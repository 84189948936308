<template>
  <div class="arts-center">
    <!-- <div class="operate-container">
      <a-input-search
        class="search-input"
        v-model.trim="inputValue"
        placeholder="Search"
        enter-button="Search"
        size="large"
        :disabled="isLoading"
        @search="onSearch"
      >
        <a-icon class="search-icon" slot="prefix" type="search"/>
      </a-input-search>

      <a-pagination
        show-quick-jumper
        :defaultPageSize="12"
        :default-current="pageNum"
        :total="50"
        :disabled="isLoading"
        @change="pageHandleChange"
      />
    </div> -->
    <div class="pre-loading" v-if="isPreLoading">
      <dotsIcon/>
    </div>
    <div class="pre-loading" v-else-if="tip">
      <div class="tip">{{ tip }}</div>
    </div>

    <div class="list-container" v-else-if="arts.length > 0">
      <template>
        <div class="art-item" v-for="(item, index) in arts" :key="index">
          <div class="top">
            <a-icon class="pause-circle" v-if="!isLoading && item.isPlaying" @click="playPause(item)" type="pause-circle" theme="filled" />
            <a-icon class="play-circle" v-else-if="!isLoading" @click="playPause(item)" type="play-circle" theme="filled" />
            <video v-show="item.isPlaying"
              class="video"
              :ref="`videoPlayer${index}`"
              :src="item.videoUrl"
              :muted="item.muted"
              :loop="item.loop"
              :preload="item.preload"
              :poster="item.poster"
              @playing="item.isPlaying = true"
              @pause="item.isPlaying = false"
              @ended="item.isPlaying = false">
            </video>
            <imageSk v-show="isLoading"/>
            <img class="img" v-show="!isLoading && !isError && !item.isPlaying" :src="item.poster" @load="onLoad" @error="onError">
            <div v-show="isError" class="error-message">Image loading failed</div>
          </div>
          <div class="info">
            <div class="text-skeleton-container" v-if="isLoading && !isError">
              <textSk width="95px" height="15px"/>
              <textSk width="80px" height="25px"/>
              <textSk width="135px" height="15px" marginTop="25px"/>
            </div>

            <div v-else>
            <div class="title">ArtsDAO NFT</div>
              <div class="name">
                <img class='ethereum-icon' src="@/assets/network/matic.svg" alt="">
                {{ item.claimedName }}
              </div>
              <div class="claimed-tip" v-if="item.claimedName">NFT has been claimed</div>
              <div class="details" v-else @click="goArtDetailsPage(item, index)">View Details</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dotsIcon from '@/components/loadingIcons/dotsIcon.vue'
import textSk from '@/components/skeleton/textSk.vue'
import imageSk from '@/components/skeleton/imageSk.vue'

export default {
  name: 'ShowCenter',

  components: {
    dotsIcon,
    textSk,
    imageSk
  },

  data () {
    return {
      tip: '',
      isLoading: true,
      isError: false,
      isPreLoading: false,
      inputValue: '',
      pageNum: 1,
      total: 50,
      arts: []
    }
  },

  computed: {
    ...mapGetters(['reverseFullName']),

    playingIndex () {
      return this.arts.findIndex(item => item.isPlaying)
    },

    isPlaying () {
      return this.playingIndex !== -1
    }
  },

  created () {
    this.getArts()
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll, false)
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    onSearch () {
      console.log(this.inputValue)
    },

    onLoad () {
      this.isLoading = false
    },

    onError (e) {
      this.isError = true
      console.error(e)
    },

    goArtDetailsPage (item, index) {
      this.$router.push({
        path: '/arts/details',
        params: { item: item },
        query: {
          id: index
        }
      })
    },

    handleScroll (e) {
      const dom = document.documentElement
      const clientHeight = dom.clientHeight
      const scrollTop = dom.scrollTop
      const scrollHeight = dom.scrollHeight

      if ((clientHeight + scrollTop) > scrollHeight - 50) {
        console.log('滚动条滑动至底部')
      }
    },

    itemRender (current, type, originalElement) {
      if (type === 'prev') {
        return <a>Previous</a>
      } else if (type === 'next') {
        return <a>Next</a>
      }
      return originalElement
    },

    async getArts () {
      this.isPreLoading = true

      try {
        const len = await this.$contracts.artsDAOAirdrop().length()

        if (len.toNumber() > 0) {
          const claimedNames = await this.$contracts.artsDAOAirdrop().slice(0, len - 1)
          console.log('claimedNames:', claimedNames)

          this.createArts(claimedNames)
        } else {
          this.createArts()
        }
      } catch (err) {
        this.tip = 'Query failed, please try again later.'
        console.log(err)
      } finally {
        this.isPreLoading = false
      }
    },

    createArts (claimedNames) {
      for (let i = 0; i < 36; i++) {
        this.arts.push(
          {
            videoUrl: 'https://openseauserdata.com/files/61650cbabc54a63357e13d0f86577315.mov',
            poster: require('@/assets/arts/poster.png'),
            muted: true,
            loop: true,
            preload: 'none',
            isPlaying: false,
            claimedName: claimedNames ? claimedNames[i] : undefined
          }
        )
      }
    },

    playPause (item) {
      const index = this.arts.indexOf(item)
      const player = this.$refs[`videoPlayer${index}`][0]
      // Current item
      if (index === this.playingIndex) {
        if (player.paused) {
          player.play()
          player.controls = true
          item.isPlaying = true
        } else {
          player.pause()
          player.controls = false
          item.isPlaying = false
        }
      } else {
        // Other item
        if (this.isPlaying) {
          // Pause playing item
          this.$refs[`videoPlayer${this.playingIndex}`][0].pause()
          this.arts[this.playingIndex].isPlaying = false
        }
        // Play from the beginning
        player.currentTime = 0
        player.play()
        player.controls = true
        item.isPlaying = true
      }
    }

    // pageHandleChange (pageNum, pageSize) {
    //   this.pageNum = pageNum
    //   console.log(pageNum)
    // }
  }
}
</script>

<style lang="scss" scoped>
.arts-center {
}
// .operate-container {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 60px;
//   ::v-deep .ant-btn-primary {
//     background-color: $blueOth;
//     border-color: #3A4EFF;
//   }
//   ::v-deep .ant-input {
//     &:hover {
//       border-color: #3A4EFF;
//     }

//     &:focus {
//       border-color: #3A4EFF;
//       box-shadow: 0 0 0 2px $boxShadowColorOth;
//     }
//   }

//   // ant-pagination
//   ::v-deep .ant-pagination-item{
//     &:hover {
//       border-color: $blue;
//     }
//   }
//   ::v-deep .ant-pagination-item a {
//     color: #2c3e50;
//     &:hover {
//       color: $blue;
//     }
//   }

//   ::v-deep .ant-pagination-item-active {
//     color: $blue;
//     border-color: $blue;
//   }

//   ::v-deep .ant-pagination-item-link {
//     color: $blue;
//     border-style: none;
//     &:active {
//       border-color: $blue;
//     }
//   }
//   ::v-deep .ant-pagination-next:hover {
//     color: $blue;
//   }
//   .ant-pagination-options {
//     border-style: none;
//   }

//   ::v-deep .ant-pagination-options-quick-jumper {
//     color: $blueOth;
//     font-size: 16px;
//     font-weight: 500;
//   }
//   ::v-deep .ant-pagination-options-quick-jumper input {
//     &:hover, &:focus  {
//       border-color: $blue;
//     }
//   }
//   .search-input {
//     max-width: 580px;
//     margin: 32px;
//     margin-left: 0;
//       ::v-deep .ant-input-lg {
//         padding-left: 40px;
//       }
//       .search-icon {
//         font-size: 20px;
//       }
//     }
//   }

.pre-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  font-size: 16px;
  min-height: 680px;
  .tip {
    color: $blue;
  }

  // .loading {
  //   font-size: 20px;
  // }

  // .loading-icon {
  //   font-size: 28px;
  //   color: $blueOth;
  //   animation: mymove 1s infinite;
  //   animation-timing-function: linear;
  //   animation-delay: -2s;
  // }

  // @keyframes mymove {
  //   from {
  //     transform: rotate(0deg);
  //   }
  //   to {
  //     transform: rotate(360deg);
  //   }
  // }
}

.list-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  .art-item {
    margin: 12px;
    width: 268px;
    font-size: 16px;
    border-radius: 16px;
    background: #fff;
    overflow: hidden;

    .top {
      position: relative;
      width: 100%;
      height: 268px;
      overflow: hidden;
      .error-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: bold;
        color: $valueColor;
      }

      .video, .img {
        width: 100%;
        height: 100%;
        // pointer-events: none;
        user-select: none;
        transition: all 0.6s;

        &:hover {
          transform: scale(1.1);
        }
      }
      .play-circle, .pause-circle {
        position: absolute;
        bottom: 14px;
        right: 10px;
        font-size: 40px;
        color: rgba(#aaa, 0.5);
        z-index: 2;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          color: rgba(#666, 0.8);
        }
      }
    }

    .info {
      text-align: left;
      padding: 16px;
      position: relative;
      height: 120px;
      .text-skeleton-container {
        display: flex;
        flex-direction: column;
      }
      .title {
        font-weight: 700;
        font-size: 16px;
        text-align: left;
        color: #2c3e50;
      }
      .name {
        margin: 12px 0;
        color: $blueOth;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
        word-break: break-all;
        .ethereum-icon {
          height: 24px;
          padding-right: 8px;
          padding-bottom: 5px;
        }
      }
      .details, .claimed-tip {
        position: absolute;
        height: 0;
        left: 0;
        right: 0;
        bottom: 0;
        line-height: 32px;
        font-weight: bold;
        text-align: center;
        background: $blueOth;
        color: #fff;
        transition: all 0.3s;
        cursor: pointer;
        user-select: none;
      }
      .claimed-tip {
        cursor: unset;
      }
    }

    &:hover {
      box-shadow: 0 6px 12px rgba(58, 78, 255, 0.15);
      .details, .claimed-tip {
        height: 32px;
      }
    }
  }
}

@media screen and (max-width: $mobileWidth) {
  .pre-loading {
    min-height: 480px;
  }
  .operate-container {
    flex-direction: column;
    padding: 0 32px;
    .search-input {
      margin: 0 0 32px 0;
    }
  }

  .list-container {
    justify-content: center;
  }
}
</style>
