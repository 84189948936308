<template>
  <div class="text-skeleton" :style="{ marginTop: marginTop, width: width, height: height }"></div>
</template>

<script lang="js">
export default {
  name: 'textSk',
  props: {
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    marginTop: {
      type: String,
      required: false,
      default: '10px'
    }
  }
}
</script>

<style lang="scss">
.text-skeleton{
  border-radius: 32px;
  background-color: #E5E8EB;
  animation: pulse 0.5s ease-in-out infinite;
}

@keyframes pulse {
  0% { opacity: 0.4; }
  50% { opacity: 0.8; }
  100% { opacity: 0.4; }
}
</style>
