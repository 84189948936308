<template>
  <div class="image-skeleton" :style="{ width: width, height: height }"></div>
</template>

<script lang="js">
export default {
  name: 'imageSk',
  props: {
    width: {
      type: String,
      required: false,
      default: '100%'
    },
    height: {
      type: String,
      required: false,
      default: '100%'
    }
  }
}
</script>

<style lang="scss">
.image-skeleton {
  background-color: #E3E6E9;
  background-image: linear-gradient(-90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
  background-size: 200%;
  animation: pulse 1.25s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    background-position: 0% center;
  }
  100% {
    background-position: 200% center;
  }
}

</style>
